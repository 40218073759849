.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}
.Toastify{
    z-index: 2147483647;
}
.font-weight-bold{
    font-weight: bold;
}
.font-weight-bolder{
    font-weight: bold;
}
.day-cell{
    width:8%;
    text-align:center;
    border: 0px solid red;
    margin-left: 30px;
    padding: 10px;
}
.day-span {
    border: 1px solid black;
    padding: 5px;
    background-color: #5b5a5a;
    cursor: pointer;
}
.day-saturday {
    background-color: #363535;
    cursor: not-allowed;
}
.day-blocked {
    cursor: not-allowed;
}
.day-sickness {
    background-color: orange;
}
.day-homeoffice {
    background-color: #7416cc;
}
.day-sickness-half-s-holiday-e {
    background: linear-gradient(-50deg, darkred 50%, orange 50%);
}
/*
background-image: -o-linear-gradient(-62deg,#0f0 50%,#f08080 50%)!important;
background: linear-gradient(-50deg,#0f0 50%,#f08080 50%);*/
.day-holiday-half-s {
    background: linear-gradient(-50deg, rgba(0, 255, 0, 0) 50%, darkred 50%);
}
.day-holiday-half-e {
    background: linear-gradient(-50deg, darkred 50%, rgba(0, 255, 0, 0) 50%);
}
.day-vacation-accepted {
    background-color: green;
}
.day-vacation-accepted-half-s {
    background: linear-gradient(-50deg, rgba(0, 255, 0, 0) 50%, green 50%);
}
.day-vacation-accepted-half-e {
    background: linear-gradient(-50deg, green 50%, rgba(0, 255, 0, 0) 50%);
}
.day-vacation-accepted-half-s-holiday-e {
    background: linear-gradient(-50deg, darkred 50%, green 50%);
}
.day-vacation-accepted-half-e-holiday-s {
    background: linear-gradient(-50deg, green 50%, darkred 50%);
}

.day-vacation-request {
    background-color: #a4a424;
}
.day-vacation-request-half-s {
    background: linear-gradient(-50deg, rgba(0, 255, 0, 0) 50%, #a4a424 50%);
}
.day-vacation-request-half-e {
    background: linear-gradient(-50deg, #a4a424 50%, rgba(0, 255, 0, 0) 50%);
}
.day-vacation-request-half-s-holiday-e {
    background: linear-gradient(-50deg, darkred 50%, #a4a424 50%);
}
.day-vacation-request-half-e-holiday-s {
    background: linear-gradient(-50deg, #a4a424 50%, darkred 50%);
}


.day-sunday {
    background-color: #232222;
    cursor: not-allowed;

}
.day-holiday {
    background-color: darkred;
    cursor: not-allowed;

}
.day-last-of-month  {
    border-left: 5px solid grey;
}
.emp-cell{
    border-right: 5px solid grey;

}
.month-cell {
    border-left: 5px solid grey;
    border-right: 5px solid grey;
    font-weight: bold;
}
.tooltip{
    z-index: 10000000000000;
}
/* jetbrains-mono-100 - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 100;
    src: url('assets/fonts/jetbrains-mono-v11-latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-100.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-100.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-200 - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 200;
    src: url('assets/fonts/jetbrains-mono-v11-latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-200.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-200.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-300 - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 300;
    src: url('assets/fonts/jetbrains-mono-v11-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-300.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-300.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-regular - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/jetbrains-mono-v11-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-regular.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-500 - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/jetbrains-mono-v11-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-500.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-500.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-600 - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/jetbrains-mono-v11-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-600.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-600.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-800 - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 800;
    src: url('assets/fonts/jetbrains-mono-v11-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-800.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-800.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-700 - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/jetbrains-mono-v11-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-700.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-700.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-100italic - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 100;
    src: url('assets/fonts/jetbrains-mono-v11-latin-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-100italic.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-100italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-200italic - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 200;
    src: url('assets/fonts/jetbrains-mono-v11-latin-200italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-200italic.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-200italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-italic - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 400;
    src: url('assets/fonts/jetbrains-mono-v11-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-300italic - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 300;
    src: url('assets/fonts/jetbrains-mono-v11-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-300italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-500italic - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 500;
    src: url('assets/fonts/jetbrains-mono-v11-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-500italic.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-500italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-600italic - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 600;
    src: url('assets/fonts/jetbrains-mono-v11-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-600italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-800italic - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 800;
    src: url('assets/fonts/jetbrains-mono-v11-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-800italic.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-800italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-700italic - latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 700;
    src: url('assets/fonts/jetbrains-mono-v11-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('assets/fonts/jetbrains-mono-v11-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('assets/fonts/jetbrains-mono-v11-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('assets/fonts/jetbrains-mono-v11-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('assets/fonts/jetbrains-mono-v11-latin-700italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
body {
    font-family: 'JetBrains Mono', sans-serif;
    background: #121212;
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #c1c7c9;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.bg-content{
    background-color: #121212;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #080808;
  color: #fff;
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  background: #000000;
}

.sidebar-header h3{
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
    color: #fff;
    padding: 10px;
}

.menu-open {
  background: #000000;
}

.nav-item:hover {
  background: #000000;
    /* color: #7386D5; */
    /* background: #fff; */
}
.nav-link{
    color: white;
}

.items-menu {
  color: #fff;
  background: #000000;
}
/*
li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%
}
*/
.sidebar-header > a {
    position: relative;
    float: right;
    margin: .5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}
li a.dropdown-toggle::after {
    display: inline-flex;
    /* position: relative; */
    /* left: 60%; */
    /* top: 10%; */
    float: right;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.select__single-value{
    color: white!important;
}
.select__menu{
    z-index: 2147483647!important;
}
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}

@media only screen and (max-width: 576px) {
    body {
      overflow:  hidden;
    }

    .content.is-open {
      margin-left: 100%;
    }

    .sidebar.is-open {
      min-width: 100%;
      max-width: 100%;
      margin-left: 0;
      transition: all 0.5s, height 0s;
    }

    .sidebar.is-open > .sidebar-header a{
      display: unset;
    }

    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }

    .navbar {
      margin-top: -20px;
      margin-left: -20px;
      margin-right: -20px;
    }
}
.react-resizable {
    position: relative;
}
.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}
.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}
.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}
.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}
.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}
.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}
.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}
.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
}

.drag-bar {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.drag-bar:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
/**
todo: for max width x
 */
.dropdown-menu.show{
    z-index: 9999999999!important;

    width: 360px!important;;
}
.table>:not(caption)>*>* {
    background-color: #202326;

}
.bg-darker {
    background-color: #030303 !important;
}
#loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    background-color: white;
}

.nav-icon{
    width: 15px!important;
}