.Login {
    border: 2px solid #d3d3d3;
    border-radius: .5em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding: 1em;
    text-align: left;
    width: 600px;
}

.form {
    padding: 1em;
}

label {
    font-weight: 600;
}

.background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;

    mask-image: radial-gradient(red 0%, red 30%, transparent 80%, transparent);
}

.bg-login-form {
    background-color: rgba(3, 3, 3, 0.75) !important;
}